@import "../../../palette.scss";

@mixin header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 116px;
  overflow: hidden;
}

.contacts_header {
  @include header;
  padding: 0 24px;
  background-color: $chatbox;

  .title {
    font-weight: 800;
    font-size: 32px;
    letter-spacing: -0.32px;
    user-select: none;
  }
}

.contacts_search {
  padding: 0 24px;
  padding-bottom: 16px;
  background-color: $chatbox;

  .temp {
    width: 100%;
    height: 48px;
    background-color: #fff;
    border-radius: 24px;
  }
}

.contacts_list {
  height: calc(100vh - 116px - 48px - 16px);
  background-color: $shape;

  .empty_contacts {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.16px;
    color: #958aab;
  }

  .contact {
    display: flex;
    background-color: $chatbox;

    .roominfo {
      display: flex;
      align-items: center;
      width: calc(100% - 74px);
      height: 112px;
      cursor: pointer;
      transition: 0.08s linear;

      &_image {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 112px;
        height: 100%;

        .thumb {
          position: relative;
          width: 64px;
          height: 64px;

          .image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            will-change: filter;
            filter: drop-shadow(0 12px 16px $primary2IdleShadow);
          }

          .messqty {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -3px;
            right: -3px;
            width: 28px;
            height: 28px;
            font-size: 12px;
            font-weight: 700;
            color: $fontWhite;
            border: 3px solid $chatbox;
            border-radius: 50%;
            background-color: $fontNotification;
          }
        }
      }

      &_label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 112px;
        overflow: hidden;
        border-bottom: 1px solid $spacer;

        .labelttl {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: -0.16px;
          color: $chatfont1;
          margin-bottom: 4px;
        }

        .labeltxt {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
          font-size: 13px;
          letter-spacing: -0.08px;
          color: $chatfont2;
        }
      }
    }

    .dropmenu {
      display: flex;
      align-items: center;
      height: 112px;
      padding: 0 24px;
      border-bottom: 1px solid $spacer;
    }

    &_before {
      border-radius: 0 0 0 32px;
    }

    &_after {
      border-radius: 32px 0 0 0;
    }

    &:hover {
      background-color: $chathover;
    }
  }

  .contact_active {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }
}

.chat {
  display: flex;
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: $shape;
  z-index: 800;

  .contacts {
    transition: 0.12s ease-in-out;
    z-index: 1;
  }

  .dialogs {
    transition: 0.12s ease-in-out;
    z-index: -1;

    .dialogs_header {
      @include header;
      padding: 0 32px;
      background-color: $chatoverlay;
    }

    .dialogs_messages {
      width: 100%;
      height: calc(100vh - 116px - 120px);

      .dialogs_scroll {
        display: flex;
        flex-direction: column;
        padding: 32px;

        .message {
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          max-width: 80%;
          height: auto;
          margin-bottom: 24px;
          padding: 18px 24px;
          line-height: 20px;
          border-radius: 16px;

          &_owner {
            align-self: flex-end;
            background-color: $ownermessage;
            color: $fontWhite;
          }

          &_opponent {
            align-self: flex-start;
            background-color: $opponentmessage;
            color: $fontTitle;
          }
        }
      }
    }

    .dialogs_newmessage {
      display: flex;
      align-items: center;
      width: 100%;
      height: 120px;
      padding: 0 32px;
      background-color: $chatoverlay;
      z-index: -1;

      .write {
        width: 100%;
        position: relative;

        .send {
          position: absolute;
          top: 50%;
          right: 16px;
          border: 0;
          background-color: transparent;
          outline: none;
          transition: 0.08s linear;
          cursor: pointer;
          transform: translateY(-50%);

          &:hover {
            transform: translateY(-50%) scale(1.15);
          }

          &:active {
            transform: translateY(-50%) scale(1);
          }
        }
      }
    }
  }
}

.skajfasjfas {
  color: #ffffffd8;
}
