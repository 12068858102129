@import "../../palette.scss";

.welcome {
  width: 100%;
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: -0.024em;
  color: $fontWelcome;

  .searchloading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 160px;
  }
}

@media (min-width: 375px) {
  .welcome {
    margin-bottom: 32px;
    font-size: 31px;
    line-height: 34px;
  }
}

@media (min-width: 640px) {
  .welcome {
    margin-bottom: 40px;
    font-size: 48px;
    line-height: 40px;
  }
}

@media (min-width: 1024px) {
  .welcome {
    font-weight: 700;
    margin-bottom: 40px;
    font-size: 56px;
    letter-spacing: -0.024em;
    line-height: 43px;
  }
}
