@import "../../../palette.scss";

@mixin photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $fontWhite;
  mask-image: url(../../../Assets/Masks/commonBtn.svg);
  mask-size: cover;
  mask-repeat: no-repeat;
}

.photos {
  width: 100%;

  &_title {
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 600;
  }

  &_previews {
    display: flex;
    width: 100%;

    .photoCont {
      position: relative;
      width: calc(20% - 3.2px);
      height: 0;
      padding-top: calc(20% - 3.2px);
      margin-left: 4px;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      .photo {
        @include photo;
        object-fit: cover;
      }

      &::after {
        content: "";
        @include photo;
        background-color: $galleryTint;
        opacity: 0;
        transition: 0.08s linear;
      }

      &:hover::after {
        opacity: 1;
      }

      &:active::after {
        opacity: 0.7;
      }

      & > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        transform: translate(-50%, -50%);
        fill: $fontWhite;
        z-index: 20;
      }
    }
  }

  &_dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 16px;
    border: 2px solid $outlineStroke;
    border-radius: 16px;
    background-color: $outline;
    color: $primaryHover;
    cursor: pointer;
    transition: 0.08s linear;

    &:hover {
      border: 2px solid $outlineHover;
    }

    &:active {
      color: $outlineFontClicked;
      border: 2px solid $outlineClick;
      background-color: $outlineClick;
    }

    .dropok {
      user-select: none;
      text-align: center;
    }

    .droperror {
      user-select: none;
      text-align: center;
      color: $fontDanger;
    }
  }
}
