.Toastify__toast-container,
.Toastify__toast-container--bottom-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
  padding: 0;
  overflow: visible;
  box-shadow: none;
  background-color: transparent;
  appearance: none;

  .Toastify__toast {
    padding: 0;
    overflow: visible;
    box-shadow: none;
    background-color: transparent;
    appearance: none;

    .Toastify__toast-body {
      padding: 0;
      appearance: none;
    }
  }
}
