@import "../../../palette.scss";

@mixin btnhover {
  filter: drop-shadow(0 12px 16px $primary2IdleShadow);
  transform: scale3d(1.1, 1.1, 1);
  -webkit-filter: blur(0px); 
  -moz-filter: blur(0px); 
  -ms-filter: blur(0px);
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
}

@mixin btnactive {
  filter: drop-shadow(0 4px 8px $primary2IdleShadow);
  transform: scale3d(1, 1, 1);
}

.logo {
  will-change: filter;
  display: flex;
  align-items: center;
  filter: drop-shadow(0 12px 16px $primary2IdleShadow);
  transition: 0.08s ease-in-out;
  transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;

  .image {
    margin-right: 10px;
  }

  .obmen {
    display: none;
    margin-right: 1px;
    transform: translateY(-1px);
  }

  .market {
    display: none;
    transform: translateY(4px);
  }

  &:hover {
    transform: scale3d(1.04, 1.04, 1);
  }

  &:active {
    transform: scale3d(1, 1, 1);
    filter: drop-shadow(0 4px 8px $primary2IdleShadow);
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0;
  transition: 0.06s ease-in-out;

  .pad {
    flex-shrink: 0;

    .authorized {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(42px * 2 + 16px * 1);

      .user {
        text-decoration: none;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        will-change: filter;
        filter: drop-shadow(0 12px 16px $primary2IdleShadow);
        transition: 0.08s linear;

        & > img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }

        &:hover {
          @include btnhover;
        }

        &:active {
          @include btnactive;
        }
      }

      .authbutton {
        position: relative;
        width: 42px;
        height: 42px;
        cursor: pointer;
        will-change: filter;

        .canvas {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: $headerButton;
          filter: drop-shadow(0 12px 16px $primary2IdleShadow);
          transition: 0.08s linear;
          will-change: filter;

          &_active {
            background-color: $primary;
          }

          &:hover {
            @include btnhover;
            background-color: $primaryHover;
          }

          &:active {
            @include btnactive;
          }
        }

        .note {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -6px;
          right: -6px;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background-color: $fontNotification;
          font-size: 12px;
          font-weight: 700;
          color: $fontWhite;
        }
      }
    }

    .loginButton {
      text-decoration: none;
    }
  }
}

@media (min-width: 480px) {
  .logo {
    .obmen {
      display: block;
    }

    .market {
      display: block;
    }
  }
}

@media (min-width: 640px) {
  .header {
    margin: 44px 0;
    .pad {
      .user {
        & > p {
          display: block;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .header {
    margin: 72px 0 64px;
  }
}
