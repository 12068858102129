@import "../../../palette.scss";

.author {
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: 0.12s ease-in-out;

    &:hover {
      & > p {
        color: $primary;
      }
    }

    & > img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 12px;
    }

    & > p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.08px;
      color: $fontTitle;
    }
  }
