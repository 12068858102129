@import "../../../palette.scss";

.statusbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  .offers {
    display: flex;
    align-items: center;
    & > img {
      margin-top: 0px;
      margin-right: 9px;
    }
    & > p {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.16px;
      color: $fontTitle;
    }
  }
  .timing {
    display: flex;
    align-items: center;
    & > p {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      letter-spacing: -0.08px;
      color: $fontTitle;
    }
    & > img {
      margin-top: -2px;
      margin-left: 9px;
    }
  }
}
