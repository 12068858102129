// FONTS

$fontWhite: #ffffff;
$fontWelcome: #c8c5d9;
$fontGrey: #4e4c54;
$fontTitle: #160242;
$fontActive: #3c148f;
$fontDanger: #ff2b2b;
$fontNotification: #ff2b2b;
$fontDisabled: #b9b5db;
$fontSub: #948ea3;

// CTA BUTTON

$primary: #7000ff;
$primary2IdleShadow: #1a1a1a3f;
$primary2HoverShadow: #1a1a1a48;
$primaryTransparent: #7000ffc0;
$primaryHover: #7831fb;
$primaryActive: #f7f6f8;
$primaryDisabled: #f7f6f8;
$primaryFontClicked: #ccd1ff;

$ctaIconHover1: #ffffff;
$ctaIconHover2: #a966ff;

// OUTLINE BUTTON

$outline: #f7f6f8;
$outlineStroke: #efecff;
$outlineHover: #c6bcff;
$outlineClick: #e7e3ff;
$outlineFontClicked: #4617a8;

// COMMON

$headerButton: #4d4765;
$shape: #f7f6f8;
$formSpacer: #e1e1e8;
$spacer: #C8C5D9;
$galleryTint: #1E0C35B3;
$galleryTintOpaque: #413f4644;
$modalfill: #080314e5;

$disabledText: #d4d1eb;
$disabledIdle: #f9f8ff;
$disabledStrike: #efecff;

// CHAT

$chatbox: #f7f6f8;
$chathover: #d4d2dd;
$chatoverlay: #ffffff60;
$chatfont1: #3d3158;
$chatfont1hover: #a09cae;
$chatfont2: #958aab;
$ownermessage: #7000ff;
$opponentmessage: #F7F6F8;
