@import "../palette.scss";

.app {
  position: relative;

  .progress {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: 0.08s linear;
    z-index: 2000;

    &_active {
      opacity: 1;
    }
  }

  .container {
    margin: 0 auto;
    padding: 0 24px;
    min-width: 320px;
    max-width: 1024px;

    .chatLoading {
      position: fixed;
      top: 50vh;
      left: 50vw;
      transform: translateY(-50%, -50%);
    }
  }
}

@media (min-width: 375px) {
  .container {
    padding: 0 32px;
  }
}
@media (min-width: 1024px) {
  .container {
    padding: 0 56px;
  }
}
