@import "../../../palette.scss";

.lotlist {
  &_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  &_pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 32px 0;
    margin-bottom: 16px;

    .message {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 56px;
      padding: 0 16px;
      // text-align: center;
    }
  }

  // &_loader {
  //   width: 100%;
  //   height: 300px;
  // }
}

.lot {
  display: block;
  width: 100%;
  position: relative;
  padding-top: 16px;
  border-bottom: 1px solid $spacer;
  margin-bottom: 16px;
  transition: 0.08s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -24px;
    display: block;
    width: calc(100% + 48px);
    height: calc(100% + 1px);
    background-color: #f6f7f8;
    opacity: 0;
    transition: 0.12s linear;
    z-index: -1;
  }

  &:active::before {
    opacity: 1;
  }

  &:hover {
    .content {
      .title {
        color: $primary;
      }
    }
  }

  .author {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: fit-content;
    text-decoration: none;
    transition: 0.12s linear;

    &:hover > {
      opacity: 0.7;
    }

    & > img {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 16px;
      margin-right: 8px;
      will-change: filter;
    }

    & > p {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.08px;
      color: $fontTitle;
      margin-right: 16px;
    }
  }

  .content {
    text-decoration: none;
    cursor: pointer;

    .photo {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: calc(100% * 0.563);
      margin-bottom: 24px;
      will-change: filter;

      & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        mask-image: url(../../../Assets/Masks/lotPhoto.svg);
        mask-size: cover;
        mask-repeat: no-repeat;
      }

      &:hover {
        & > img {
          filter: grayscale(0%);
        }
      }
    }

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-style: normal;
      font-weight: 800;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.4px;
      color: $fontTitle;
      margin-bottom: 15px;
      filter: drop-shadow(0 12px 16px $primary2IdleShadow);
      -webkit-filter: blur(0px); 
      -moz-filter: blur(0px); 
      -ms-filter: blur(0px);
      filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
      transition: 0.19s ease-in-out;
    }

    .description {
      overflow-y: hidden;
      min-height: 66px;
      font-size: 14px;
      line-height: 1.7em;
      /* or 143% */
      letter-spacing: -0.0008em;
      color: $fontGrey;
      margin-bottom: 22px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

@media (min-width: 640px) {
  .lot {
    width: calc(50% - 16px);
  }
}

@media (min-width: 1024px) {
  .lot {
    width: calc(50% - 28px);
  }
}
