@import "../../../palette.scss";

@mixin placeholder {
  &::placeholder {
    color: $fontGrey;
    transition: 0.08s;
  }

  &:focus::placeholder {
    opacity: 0;
  }
}

.input {
  position: relative; // for photofiles

  .textinput {
    width: 100%;
    height: 52px;
    border: 0;
    border-bottom: 1px solid $formSpacer;
    background-color: transparent;
    color: $fontTitle;
    outline: none;

    @include placeholder;
  }

  .message {
    width: 100%;
    height: 56px;
    padding: 0 58px 0 16px;
    border: 0;
    border-radius: 16px;
    background-color: $fontWhite;
    color: $fontTitle;
    outline: none;

    @include placeholder;
  }

  .textarea {
    width: 100%;
    min-height: 52px;
    padding-top: 16px;
    border: 0;
    border-bottom: 1px solid $formSpacer;
    background-color: transparent;
    color: $fontTitle;
    resize: none;
    overflow-y: auto;
    outline: none;

    @include placeholder;
  }

  .checkbox {
    position: relative;
    margin-top: 16px;

    & > input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -20;
    }

    & > label {
      user-select: none;
    }

    & > input + label {
      position: relative;
      margin-left: 28px;
      font-weight: 600;
      cursor: pointer;
    }

    & > input + label:before {
      content: "";
      position: absolute;
      top: 50%;
      left: -28px;
      width: 20px;
      height: 20px;
      border-radius: 7px;
      background-color: transparent;
      border: 2px solid $outlineHover;
      transform: translateY(-50%);
      background-position: center;
      background-size: 60%;
      background-repeat: no-repeat;
    }

    & > input:checked + label::before {
      background-image: url(../../../Assets/Icons/v_check_active.svg);
    }
  }

  .uploadcheck {
    display: none;
  }

  .sup {
    display: flex;
    align-items: center;

    & > img {
      margin-right: 8px;
    }

    & > P {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.08px;
      color: $fontGrey;
      user-select: none;
    }
  }

  .sub {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.149333px;
    color: $fontSub;
    user-select: none;
  }

  .error {
    color: $fontDanger;
  }
}
