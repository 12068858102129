@import "../../palette.scss";

.login {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 24px;
  .shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $shape;
    padding: 24px;
    border-radius: 16px;
    background-color: $shape;
    z-index: -10;
  }
  & > p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .providers {
    display: flex;
    align-items: center;
    & > button {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
