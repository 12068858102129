@import "../../palette.scss";

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 40px;
  margin-bottom: 40px;
  // background-color: $shape;
  // border-radius: 16px;
  // z-index: -1;
  .shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $shape;
    border-radius: 16px;
    z-index: -1;
  }

  .photo {
    border-radius: 50%;
    width: 126px;
    height: 126px;
    object-fit: cover;
  }

  .info {
    width: 100%;

    .userName {
      font-weight: 800;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.64px;
      color: $fontTitle;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .buttons {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 40px;

      & > button {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .details {
      display: flex;
      flex-wrap: wrap;

      .item {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 14px;

        & > p {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: -0.08px;
          color: $fontGrey;
          margin-left: 13px;
        }
      }
    }
  }
}

@media (min-width: 480px) {
  .userInfo {
    .info {
      .buttons {
        flex-direction: row;
        & > button {
          margin-right: 16px;
          margin-bottom: 0;
        }
      }
    }
  }
}
@media (min-width: 640px) {
  .userInfo {
    flex-direction: row;
    align-items: unset;

    .info {
      width: calc(100% - 30px - 126px);
      .userName {
        text-align: left;
        margin-bottom: 16px;
      }
      .buttons {
        justify-content: flex-start;
      }
      .details {
        .item {
          justify-content: flex-start;
          width: 270px;
        }
      }
    }
  }
}
