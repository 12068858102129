@import "../../../palette.scss";

.formfull {
  margin-bottom: 40px;

  .cloudtail {
    margin-left: 110px;
    margin-bottom: -3px;
    opacity: 0;
    transform: translateX(-50%);
  }

  .shape {
    width: 100%;
    padding: 32px 24px;
    margin-bottom: 28px;
    border-radius: 16px;
    background-color: $shape;
    z-index: -10;

    .fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      .pad {
        display: block;
        width: 100%;
        margin-bottom: 32px;

        &:first-child {
          margin-bottom: 0;
        }

        .title {
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.08px;
          color: $fontTitle;
          margin-bottom: 8px;
          user-select: none;
        }

        .price {
          position: relative;

          & > img {
            position: absolute;
            top: 14px;
            left: 0px;
            width: 24px;
            height: 24px;
            object-fit: cover;
            // transform: translateY(-50%);
          }
        }

        .photos {
          margin-bottom: 24px;
        }

        .hidenfields {
          display: none;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 128px;

    & > button {
      margin-bottom: 28px;
    }

    & > p {
      width: 100%;
      text-align: center;
    }
  }
}

@media (min-width: 640px) {
  .formfull {
    .cloudtail {
      opacity: 1;
    }
  }
}

@media (min-width: 768px) {
  .formfull {
    .shape {
      .fields {
        .pad {
          width: calc(50% - 12px);
        }
      }
    }
    .buttons {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      & > button {
        margin-right: 16px;
      }
      & > P {
        text-align: left;
      }
    }
  }
}

@media (min-width: 1024px) {
  .formfull {
    .buttons {
      & > p {
        width: calc(100% - 440px - 32px);
      }
    }
  }
}
