@import "../../../palette.scss";

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 8px;
  width: 100%;
  height: 72px;
  border-top: 1px solid $spacer;

  .options {
    display: flex;
    align-items: center;
    margin-right: 16px;

    .editbtn {
      position: relative;

      .cloudtail {
        position: absolute;
        left: 50%;
        bottom: -24px;
        transform: translateX(-50%);
      }
    }
  }
}

@media (min-width: 640px) {
  .controls {
    margin-top: 32px;

    .options {
      margin-right: 0;

      .editbtn {
        .cloudtail {
          bottom: -24px;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .controls {
    margin-top: 57px;
    margin-bottom: 0;
    height: 96px;

    .options {
      .editbtn {
        .cloudtail {
          bottom: -27px;
        }
      }
    }
  }
}
