@import "./reset.scss";
@import "./palette.scss";

* {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif, "Inter",
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  // -webkit-appearance: none;
  // -moz-appearance: none;
  appearance: none;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
}

html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

body {
  line-height: 1;
  font-size: 14px;
  font-weight: 400;
}

button {
  font-weight: 700;
  letter-spacing: -0.16px;
  line-height: 20px;
}

input,
textarea {
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.16px;

  &::placeholder {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.16px;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
}

.react-scrolltop-button {
  &::content {
    position: fixed;
    top: 0px;
    vertical-align: top;
  }
}

@import "./toasts.scss";
