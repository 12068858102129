.react-image-shadow {
    position: relative;
    z-index: 0;
  }
  .react-image-shadow img {
    display: block;
    transition: all 0.2s ease;
  }
  .react-image-shadow-card {
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
    bottom: -4%;
    left: 5%;
    width: 90%;
    height: 80%;
    z-index: -1;
    opacity: 0.55;
    transition: all 0.2s;
    will-change: filter;
  }
  .react-image-shadow-hover:hover img {
    transform: translateY(-4px);
  }
  .react-image-shadow-hover:hover .react-image-shadow-card {
    left: 8%;
    bottom: -6%;
    width: 86%;
  }