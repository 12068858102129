@import "../../palette.scss";

.lot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .detailes {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 24px;

    .status {
      margin-bottom: 24px;
    }

    .spacer {
      display: block;
      width: 100%;
      height: 0.5px;
      background-color: $spacer;
      margin-bottom: 32px;
    }

    .buttons {
      &_block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
      }
    }

    .offers_title {
      font-weight: 800;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.64px;
      color: $fontTitle;
      margin-bottom: 16px;
    }
  }

  .description {
    width: 100%;
    margin-bottom: 120px;

    .author {
      margin-bottom: 16px;
      font: 125%/20px bold $fontTitle;
      letter-spacing: -0.16px;
      margin-bottom: 32px;
    }

    .majortitle {
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.72px;
      font-feature-settings: 'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv10' on, 'cv09' on, 'cv08' on, 'cv07' on, 'cv06' on;
      color: $fontTitle;
      margin-bottom: 32px;
    }

    .minortitle {
      font-weight: 700;
      font-size: 15px;
      letter-spacing: -0.4px;
      color: $fontTitle;
      margin-bottom: 6px;
    }

    .majortext {
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.0008em;
      color: $fontGrey;
      margin-bottom: 32px;
      padding-bottom: 16px;
      border-bottom: 0.5px solid #C6BCFF;
    }

    .addinfo {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      &_title {
        font-weight: bold;
        font-size: 18px;
        line-height: 19px;
        letter-spacing: -0.16px;
        color: $fontTitle;
        margin-bottom: 6px;
      }

      &_value {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.16px;
        color: $fontGrey;
      }
    }
  }
}

@media (min-width: 768px) {
  .lot {
    flex-direction: row;

    .detailes {
      width: 50%;
    }

    .description {
      width: calc(50% - 39px);
    }
  }
}
